
































export default {
  name: 'CategoryHeader',
  props: {
    title: {
      type: Boolean,
      required: false,
      default: true

    },
    decorated: {
      type: Boolean,
      required: false,
      default: true
    },
    capitalized: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return ({
      dialog: false
    })
  }
}
