































































import Vue from 'vue'
import unitTools from '@/mixins/unitTools'

export default Vue.extend({
  name: 'ValueWithDataBook',
  mixins: [unitTools],
  props: {
    disabled: { type: Boolean, default: false },
    tooltip: { type: Boolean, default: true },
    highlighted: { type: Boolean, default: true },
    color: { type: String, default: '#f5f6f5' }
  },
  data () {
    return ({
      dialog: false
    })
  }
})
